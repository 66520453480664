export const musicData = {
  musics: [
    {
      YouTube: "https://www.youtube.com/embed/r075wysrfMk?si=6uupilQiEfxYG_dc",
      niconico: "https://www.nicovideo.jp/watch/sm44722788",

      Spotify: "",
      Apple_Music: "",
      YouTube_Music: "",
      LINE_Music: "",
      Amazon_Music_streaming: "",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: require("../assets/movies/list/yuyakenomahou.webp"),
      category: "OnVocal",
      color: "#F16B75",
      title: "夕焼けのまほう",
      text: "ぼくだけの物語歩いてゆく",
      art: "くえん",
      art_link: "https://twitter.com/kuwhen",
      date: "2023",
    },
      
    {
      YouTube: "https://www.youtube.com/embed/sQQKKLkpfms?si=MReLk4TccS7w_oC6",
      niconico: "https://www.nicovideo.jp/watch/sm44675235",

      Spotify: "",
      Apple_Music: "",
      YouTube_Music: "",
      LINE_Music: "",
      Amazon_Music_streaming: "",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: require("../assets/movies/list/surimain.webp"),
      category: "OnVocal",
      color: "#F16B75",
      title: "すりーまいん",
      text: "あなたは一人じゃ生きれない",
      art: "らま助",
      art_link: "https://twitter.com/rmsk_suke",
      date: "2024",
    },

    {
      Spotify: "https://open.spotify.com/intl-ja/track/50zIdgLIwgK8e9TlyrNtWk",
      Apple_Music: "https://music.apple.com/jp/album/%E6%97%85%E8%B7%AF%E3%81%B8%E3%81%AE%E6%86%A7%E6%86%AC-single/1782848038",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_neutSpXLg3VhUCYD0BXgdnoIb8G4n3xSo",
      LINE_Music: "https://lin.ee/MGerEL7o",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFV7VML?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/tabiji-heno-shoukei",
      src: require("../assets/musics/Tabiji_heno_shoukei.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "旅路への憧憬",
      text: "稚内を走りながら作った曲",
      art: "みるく",
      art_link: "https://twitter.com/clamch_owder",
      date: "2022",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/13OkJN0YWSbgGBYuuCuy6c",
      Apple_Music: "https://music.apple.com/jp/album/%E6%9C%AA%E7%9F%A5%E3%81%AE%E6%97%85%E8%B7%AF-single/1782848095",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_lTZPVgyer1LO_OW6mpHez4s0u1HnGlL5U",
      LINE_Music: "https://lin.ee/bsMU6KM",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFTDC21?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: require("../assets/musics/Michi_no_tabiji.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "未知の旅路",
      text: "10時間くらいで作った曲",
      art: "相生あおは",
      art_link: "",
      date: "2022",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/199tsYvCG8v6n1gYATAaUW",
      Apple_Music: "https://music.apple.com/jp/album/graduate-nekochan-single/1782848070",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_kadb8cefR2mdIixoG_R5zTcZ3hzCsZO6c",
      LINE_Music: "https://lin.ee/R4H89kb",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFV5TXJ?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/graduate-nekochan",
      src: require("../assets/musics/Graduate_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Graduate Nekochan",
      text: "卒業ソング",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2022",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/1cIkXvpBM3887keo1DfXs8",
      Apple_Music: "https://music.apple.com/jp/album/%E5%8B%87%E5%BE%83%E9%82%81%E9%80%B2-single/1783281604",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_mpl0q8gT4VNAXRMQSAiZXfVSsedeCJEtA",
      LINE_Music: "https://lin.ee/3Xfce8H5",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPKJT7ZC?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: require("../assets/musics/Yuuoumaishin.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "勈徃邁進",
      text: "50時間くらいで作った和風な曲",
      art: "紫餅",
      art_link: "https://twitter.com/shihei_lileep",
      date: "2022",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/5Ohv6MgodrcHTa14MxdXYa",
      Apple_Music: "https://music.apple.com/jp/album/lemonadegrenade-single/1782848103",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_nwBdxYhL89gLerFdN44HX-D7tI5JVtn5E",
      LINE_Music: "https://lin.ee/WHDXhtg",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFSF993?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/lemonadegrenade",
      src: require("../assets/musics/Lemonadegrenade.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Lemonadegrenade",
      text: "本気でクラシックに全振りした曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/2iZ8QZd2PYyz12wvcP9nmt",
      Apple_Music: "https://music.apple.com/jp/album/frontier-nekochan-single/1782848423",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_k4wMV7UJj-eNnNBQcvLC-LOmmIFIny-xI",
      LINE_Music: "https://lin.ee/8y6xD8J",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFT5RRD?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/frontier-nekochan",
      src: require("../assets/musics/Frontier_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Frontier Nekochan",
      text: "電子音と生楽器の融合曲",
      art: "umr",
      art_link: "https://twitter.com/BlazeConductor",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/0qBI37OGXaoRBLkadyTIPg",
      Apple_Music: "https://music.apple.com/jp/album/nightlude-single/1782847999",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_lqOu9tS8dghIgo9TeGmow9iHIWzBha118",
      LINE_Music: "https://lin.ee/aBfVKj6",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFT2CFK?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/nightlude",
      src: require("../assets/musics/Nightlude.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Nightlude",
      text: "可憐な舞曲",
      art: "MillQlair",
      art_link: "",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/5ZHfu2ZhQjwtrGD2H0EmRc",
      Apple_Music: "https://music.apple.com/jp/album/knitefight-single/1782847884",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_nA7xYe9GL_MkE_utYzo3rEMYoGAzeFuVM",
      LINE_Music: "https://lin.ee/yluuO2F",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFT6W5F?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: require("../assets/musics/Knitefight.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Knitefight",
      text: "戦う騎士の曲",
      art: "なれう。",
      art_link: "https://twitter.com/4444_Exxxx",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/4nXfUcmzKgXoixSGO1lZOm",
      Apple_Music: "https://music.apple.com/jp/album/glittem-single/1782847680",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_liQio07tjSoFFteOZlYYaN9B4lnADY4dI",
      LINE_Music: "https://lin.ee/M84lzPX",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFTY6J2?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "",
      src: require("../assets/musics/Glittem.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Glittem",
      text: "オシャンなバーの曲",
      art: "紫餅",
      art_link: "https://twitter.com/shihei_lileep",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/4AlIrBodqGvk5IPzlxbK7N",
      Apple_Music: "https://music.apple.com/jp/album/%E3%82%B9%E3%82%BA%E3%83%A1%E3%83%8E%E3%82%BB%E3%82%AB%E3%82%A4-single/1782847623",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_mx_KIlXCVxc2ZmpPM-CWYwv2qSmkBm-C8",
      LINE_Music: "https://lin.ee/LYGWHhLX",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFSH28V?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/suzume-no-sekai",
      src: require("../assets/musics/Suzume_no_sekai.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "スズメノセカイ",
      text: "実は自分を重ねてる曲",
      art: "洛葉みかん",
      art_link: "https://twitter.com/lac_ba_m",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/1HKYmozbhmoVN7NTDvEpHb",
      Apple_Music: "https://music.apple.com/jp/album/%E3%82%A8%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E3%82%AB%E3%83%AB-%E3%83%88%E3%82%AD%E3%82%B7%E3%83%83%E3%82%AF-single/1782847794",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_n0gcQEFX_0zRZpBb07bfKhDIDh4X25-FQ",
      LINE_Music: "https://lin.ee/onL2TLd",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFVKP4G?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/electrical-toxic",
      src: require("../assets/musics/Electrical_Toxic.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "エレクトリカル・トキシック",
      text: "栄枯盛衰な曲",
      art: "しりあ",
      art_link: "https://twitter.com/Celia_oekaki",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/2EUQLI9sNEVfPd2Xz546gc",
      Apple_Music: "https://music.apple.com/jp/album/%E5%A4%A2%E8%89%B2%E3%81%AE%E8%8A%B1%E6%9D%9F-single/1782847406",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_n6LeE4pSpfKDFxPUavBXtcSxjn-2j38r4",
      LINE_Music: "https://lin.ee/Rbo9b2P",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFTZ7JG?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/yumeiro-no-hanataba",
      src: require("../assets/musics/Yumeiro_no_hanataba.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "夢色の花束",
      text: "花束を渡す感じの曲",
      art: "7rika.",
      art_link: "",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/6Xr1RZDmGJtLXuJ1krK1Vx",
      Apple_Music: "https://music.apple.com/jp/album/popping-nekochan-single/1782848199",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_kyxWC0gmUjtMDJP6TnMBkGVRGAqH70mDA",
      LINE_Music: "https://lin.ee/xhgZrqu",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFRS7FX?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/popping-nekochan",
      src: require("../assets/musics/Popping_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Popping Nekochan",
      text: "狂詩曲的な曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/163Q4j24VyUQqlcEnAjYhT",
      Apple_Music: "https://music.apple.com/jp/album/bravelgent-single/1782847871",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_kYt1NhA9qXa0w4Cs69ekc3BA3Bhv7m7k8",
      LINE_Music: "https://lin.ee/fAKyAmh",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DPFV4JGD?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/bravelgent",
      src: require("../assets/musics/Bravelgent.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Bravelgent",
      text: "襟裳岬イメージ曲",
      art: "二兎",
      art_link: "https://twitter.com/drawing_ninjin",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/2dAXTwe9g81SenHaRyukvZ",
      Apple_Music: "https://music.apple.com/jp/album/vermitation-single/1780744782",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_m7p99QNAawj9_gMhG6VErSSxVSAv5pmtU",
      LINE_Music: "https://lin.ee/1jG9BUb",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMM7L8C?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/vermitation",
      src: require("../assets/musics/Vermitation.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Vermitation",
      text: "厨二っぽい曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2021",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/584rxTNFS13THjV80orirb",
      Apple_Music: "https://music.apple.com/jp/album/voyager-nekochan-single/1780744801",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_mW_YlXiO7o8xz7YKNqM0Yl9EFHiyuoo4E",
      LINE_Music: "https://lin.ee/dbkANJM",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNML2B89?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/voyager-nekochan",
      src: require("../assets/musics/Voyager_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Voyager Nekochan",
      text: "宇宙感あるエンディング曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2020",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/15LMAkEEDWSikn31lHzEDO",
      Apple_Music: "https://music.apple.com/jp/album/daydream-single/1780743444",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_n8JjIEiXrerfKVAxP-BVvpDOmlT3KAcFY",
      LINE_Music: "https://lin.ee/deqHbgz",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMJGS3T?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/daydream",
      src: require("../assets/musics/Daydream.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Daydream",
      text: "夢うつつな曲",
      art: "堀江",
      art_link: "",
      date: "2020",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/424YwmfAL2tC2MbxzQWXBo",
      Apple_Music: "https://music.apple.com/jp/album/skyhigh-nekochan-single/1780743872",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_lByX3pPkvzBf3zHBmVyehwmxNTT4mG1SE",
      LINE_Music: "https://lin.ee/WFrEjr2",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMNMF2W?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/skyhigh-nekochan",
      src: require("../assets/musics/Skyhigh_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Skyhigh Nekochan",
      text: "ただ楽しそうな感じの曲",
      art: "umr",
      art_link: "https://twitter.com/BlazeConductor",
      date: "2020",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/411Wu2Hg4NSrUKnzSzN70D",
      Apple_Music: "https://music.apple.com/jp/album/shiftenery-single/1780743109",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_npyImlIyzcbe070se3cgYW95Q6seQySJc",
      LINE_Music: "https://lin.ee/HAu9Etz",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMKRPT5?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/shiftenery-ksm-edit",
      src: require("../assets/musics/Shiftenery.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Shiftenery",
      text: "2日で急遽作った曲",
      art: "二兎",
      art_link: "https://twitter.com/drawing_ninjin",
      date: "2020",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/5DI5DdXboHh3fIJav5o7OP",
      Apple_Music: "https://music.apple.com/jp/album/cuddle-nekochan-single/1780743627",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_nUvcwI0Pb--aeIL7gWwyoLkIkWE_jK9Y4",
      LINE_Music: "https://lin.ee/wG799gN",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMPPVPX?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/cuddle-nekochan",
      src: require("../assets/musics/Cuddle_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Cuddle Nekochan",
      text: "癒しを求めた曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2020",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/1EX7EjOVX4hgRXtz1w3eDg",
      Apple_Music: "https://music.apple.com/jp/album/affectation-single/1780743505",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_n0zW5c7pZf4-IdWGtRqveItzdl2rJ1X2Y",
      LINE_Music: "https://lin.ee/890dsvk",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMMHL5S?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/affectation",
      src: require("../assets/musics/Affectation.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Affectation",
      text: "カッコよくつくった曲",
      art: "らま助",
      art_link: "https://twitter.com/rmsk_suke",
      date: "2020",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/6QyXb9LZ95GKh6Zv3KhhNK",
      Apple_Music: "https://music.apple.com/jp/album/asteroid-nekochan-single/1780743208",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_mXvaeKj1haY38R5TMbq4ev4yQ0JkJWE-I",
      LINE_Music: "https://lin.ee/uKLIZlh",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMP4X6J?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/asteroid-nekochan",
      src: require("../assets/musics/Asteroid_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Asteroid Nekochan",
      text: "宇宙っぽい合作曲",
      art: "あおかび(碧黴)",
      art_link: "",
      date: "2019",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/4rHROliITHiLYqNHW5lKXN",
      Apple_Music: "https://music.apple.com/jp/album/fierceness-single/1780742983",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_mTKDlccA-WJlBGh3lbRpg1ttR3A9EKh9Y",
      LINE_Music: "https://lin.ee/lUSfNK8",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMJHD6L?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/fierceness_re",
      src: require("../assets/musics/Fierceness.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Fierceness",
      text: "闇落ちあおは曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2019",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/5xJoggNSVV7U8Q7YgJQ2bU",
      Apple_Music: "https://music.apple.com/jp/album/bayside-nekochan-single/1780742617",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_nt47nqiYq8YLfiMPJ4XPVL8y76ljmz5x4",
      LINE_Music: "https://lin.ee/5lP8n1U",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMLLR12?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/bayside-nekochan",
      src: require("../assets/musics/Bayside_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Bayside Nekochan",
      text: "夏全開って感じの曲",
      art: "umr",
      art_link: "https://twitter.com/BlazeConductor",
      date: "2019",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/3NuyiRpVg9aDf3MMv2qjdg",
      Apple_Music: "https://music.apple.com/jp/album/contemplation-single/1780742830",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_lnAyf1y4ppmiAvASEE6rW6wIDY_vZnuAM",
      LINE_Music: "https://lin.ee/jUpQrxu",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMKF5RT?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/contemplation",
      src: require("../assets/musics/Contemplation.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Contemplation",
      text: "院試勉強疲れて人生諦観した感じの曲",
      art: "蛇路",
      art_link: "",
      date: "2019",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/2NiIj6t5Y3DctVY8n8ZtUP",
      Apple_Music: "https://music.apple.com/jp/album/maiden-nekochan-single/1780723866",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_kJCaRZGB3X3NvA3Z8Oud2iSRpz_S5R8aU",
      LINE_Music: "https://lin.ee/owdLYqz",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNMBTCG3?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/maiden-nekochan",
      src: require("../assets/musics/Maiden_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Maiden Nekochan",
      text: "乙女心みたいな曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2019",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/6JG5dfTQnea6XIxUnBbYnN",
      Apple_Music: "https://music.apple.com/jp/album/melancholy-single/1780723826",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_mTHWZZ_LTilMBWXPdUoqMY-QywvZ4ol5c",
      LINE_Music: "https://lin.ee/TU3T2Pu",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNM7HN9G?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/melancholie",
      src: require("../assets/musics/Melancholy.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Melancholy",
      text: "曲名通りの曲",
      art: "あおかび(碧黴)",
      art_link: "",
      date: "2019",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/0KOcagc9p5DT0dfqzjXwWz",
      Apple_Music: "https://music.apple.com/jp/album/hispeed-nekochan-single/1780723541",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_lvv4bijpcImKOvPg34_UqPKPclqS1LQcI",
      LINE_Music: "https://lin.ee/w5nW1Wd",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNM81NM8?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/hispeed-nekochan",
      src: require("../assets/musics/Highspeed_Nekochan.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Highspeed Nekochan",
      text: "ノリと勢いだけで作った曲",
      art: "相生あおは",
      art_link: "",
      date: "2018",
    },
    {
      Spotify: "https://open.spotify.com/intl-ja/track/2TQqQ68HyGBjXAS1ZHHyha",
      Apple_Music: "https://music.apple.com/jp/album/operon-single/1780723473",
      YouTube_Music: "https://music.youtube.com/playlist?list=OLAK5uy_lzlOrfWVsfbtBiXAWrPiGKR9QD1tcrTv0",
      LINE_Music: "https://lin.ee/v6VC1Hu",
      Amazon_Music_streaming: "https://amazon.co.jp/music/player/albums/B0DNM9TM1H?marketplaceId=A1VC38T7YXB528&musicTerritory=JP",
      Amazon_Music_dl: "",

      iTunes_Store: "",
      AWA: "",
      d_hits: "",
      recochoku: "",
      Deezer: "",
      KKBOX: "",
      OTORAKU: "",
      TOWER_RECORDS_MUSIC: "",
      more: "",
      music_jp: "",

      url: "https://soundcloud.com/semicolon0103/operon_re",
      src: require("../assets/musics/Operon.webp"),
      category: "Instrumental",
      color: "#039BE5",
      title: "Operon",
      text: "作曲引退を賭けて作った曲",
      art: "いもねぎ",
      art_link: "https://twitter.com/imonegi_48",
      date: "2018",
    },
  ],
}
